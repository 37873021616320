import React from 'react';
import { KonvaEventObject } from 'konva/lib/Node'
import { Circle } from 'react-konva'

type Props={
  color:string,
  readPoints:{x:number,y:number}[],
  handleDragStart:(e: KonvaEventObject<DragEvent>,i:number)=>void,
  handleDragEnd:(e: KonvaEventObject<DragEvent>,i:number)=>void,
}

export const ReadPointCircle=(props:Props)=>{

  return(
    <>
      {props.readPoints.map((rp,i)=>(
        <Circle
          key={"rp:"+i}
          x={rp.x}
          y={rp.y}
          radius={5}
          fill={props.color}
          opacity={0.5}
          onDragStart={(e)=>props.handleDragStart(e,i)}
          onDragEnd={(e)=>props.handleDragEnd(e,i)}
          draggable
          // onMouseOver={}
        />
      ))}
    </>
  )
}
