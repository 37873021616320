import React from 'react';
import Button from '@mui/material/Button'
import { Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import PaletteTwoToneIcon from '@mui/icons-material/PaletteTwoTone';

type Props={
  handleFiles:(e:React.ChangeEvent<HTMLInputElement>)=>void,
  handleTestClick:()=>void,
  handleResetClick:()=>void,
  handleAddClick:()=>void,
  handleRemoveClick:()=>void,
  handleRotateClick:(direction:string)=>void,
  handlePaletteClick:()=>void,
}

export default function ControlPanel(props:Props){

  return(
    <div>
    <Box sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',columnGap:1}}>

      <Button variant='outlined' component="label">ファイルを読み込む
        <input type="file" hidden accept="image/*" onChange={props.handleFiles}/>
      </Button>

      <Button variant='outlined'  onClick={props.handleTestClick}>クリップボードから貼付</Button>

    </Box>
    <Box sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',columnGap:0,margin:"2px"}}>
      <Button variant='text' onClick={props.handleResetClick}>RESET</Button>

      <IconButton aria-label="delete" size="small"  onClick={props.handleAddClick} color="primary">
        <AddIcon />
      </IconButton>
      <IconButton aria-label="delete"  size="small" onClick={props.handleRemoveClick} color="primary">
        <RemoveIcon />
      </IconButton>


      <IconButton size='small'color='primary' onClick={()=>props.handleRotateClick('clock-wise')} >
        <CropRotateIcon id='clock-wise'/>
      </IconButton>

      <IconButton size='small' color='primary' onClick={()=>props.handleRotateClick('counter-clock-wise')} style={{transform:'scale(-1,1)'}}>
        <CropRotateIcon id='counter-clock-wise' />
      </IconButton>

      <IconButton size='small' color='primary' onClick={()=>props.handlePaletteClick()}>
        <PaletteTwoToneIcon/>
      </IconButton>

    </Box>
    </div>
  )
}
