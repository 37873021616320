import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ContentMain from './content1/ContentMain';
import { myTheme } from './content1/myTheme';

function App() {
  document.title="GraphTracker"
  return (
    <ThemeProvider theme={myTheme}>
      <CssBaseline />
        <ContentMain />
    </ThemeProvider>
  );
}

export default App;
