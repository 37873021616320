import { Box, Checkbox, Divider, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


type Props={
  index:number,
  value:number,
  children?: React.ReactNode,
  state1:boolean,
  state2:boolean,
  valuePoint1:{x:number,y:number},
  setValuePoint1:(point:{x:number,y:number})=>void,
  valuePoint2:{x:number,y:number},
  setValuePoint2:(point:{x:number,y:number})=>void,
  logscaleX:boolean;
  setLogscaleX:(state:boolean)=>void;
  logscaleY:boolean;
  setLogscaleY:(state:boolean)=>void;
  readValueStr:string;
  recordData:string;
  handleClearRecord:()=>void;
}

export const TabPanel2=(props:Props)=>{

  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(props.recordData);
  };

  return(
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
    >
      {props.value === props.index && (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <div style={{display:'flex'}}>
                <Typography sx={{p:1}}>
                  対数目盛
                </Typography>
                <Tooltip title='log scale x'>
                  <Checkbox checked={props.logscaleX} onChange={(e)=>props.setLogscaleX(e.target.checked)}/>
                </Tooltip>
                <Tooltip title='log scale y'>
                  <Checkbox checked={props.logscaleY} onChange={(e)=>props.setLogscaleY(e.target.checked)}/>
                </Tooltip>
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextField
                size="small"
                label={'result:[x y]'}
                multiline
                value={props.readValueStr}
                fullWidth
                error={!(props.state1&&props.state2)}
                disabled
              />
            </Grid>

            <Grid item xs={12} sx={{p:1}}>
              <Divider/>
            </Grid>

            <Grid item xs={12}>
              <div style={{display:'flex'}}>
                <Typography sx={{p:1}}>
                  読み取り結果の記録
                </Typography>
                <Tooltip title='copy'>
                  <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title='delete'>
                  <IconButton  onClick={props.handleClearRecord}>
                    <DeleteForeverIcon color='warning'/>
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="records"
                multiline
                maxRows={10}
                fullWidth
                value={props.recordData}
                size="small"
                variant="filled"
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  )
}
