import React from 'react';
import { Circle } from 'react-konva'

type Props={
  color:string,
  imgscale:number,
  state1:boolean,
  state2:boolean,
  pixelPoint1:{x:number,y:number},
  pixelPoint2:{x:number,y:number},
}

export const BasePointCircle=(props:Props)=>{
  return(
    <>
      <Circle
        x={props.pixelPoint1.x-5/props.imgscale}
        y={props.pixelPoint1.y-5/props.imgscale}
        radius={props.state1 ? 4 : 0}
        fill={props.color}
        opacity={0.5}
        />
      <Circle
        x={props.pixelPoint2.x-5/props.imgscale}
        y={props.pixelPoint2.y-5/props.imgscale}
        radius={props.state2 ? 4 : 0}
        fill={props.color}
        opacity={0.5}
        />
    </>
  )
}
