import React  from 'react'
import { KonvaEventObject } from 'konva/lib/Node'
import { Box, Grid, Tab, Tabs } from '@mui/material';
import KonvaMain from './konvaMain';
import ControlPanel from './ControlPanel';
import { TabPanel1 } from './Tabs/TabPanel1';
import { TabPanel2 } from './Tabs/TabPanel2';
import { TabPanel3 } from './Tabs/TabPanel3';
import axios from 'axios';
import { zip } from './utils';
import ColorPicker from './ColorPicker';

export default function ContentMain(){
  const [imgorg, setImgOrg] = React.useState<string>("");
  const [imgscale,setImgScale] = React.useState<number>(1);
  const [state1, setState1] = React.useState<boolean>(false);//基準点1が設定されているかどうか
  const [state2, setState2] = React.useState<boolean>(false);//基準点2が設定されているかどうか
  const [logscaleX, setLogscaleX] = React.useState<boolean>(false);//横軸が対数目盛かかどうか
  const [logscaleY, setLogscaleY] = React.useState<boolean>(false);//縦軸が対数目盛かかどうか
  const [interPolationState, setInterPolationState] = React.useState<boolean>(false);//補間するかどうか
  const [rotate, setRotate] = React.useState<number>(0);//画像データの回転角度
  const [tabValue, setTabValue] = React.useState<number>(0);//選択中のタブ番号
  const [readValueStr, setReadValueStr] = React.useState<string>("");//クリックで読み取った値の文字列、表示用
  const [recordData, setRecordData] = React.useState<string>("");//クリックで読み取った値の文字列、コピー用、
  const [readPointValues, setReadPointValues] = React.useState<string>("");//ポイントで読み取った値の文字列、表示、コピー用
  const [clickedXY, setClickedXY] = React.useState<{x:number,y:number}>({x:0,y:0});
  const [mouseMoveXY, setMouseMoveXY] = React.useState<{x:number,y:number}>({x:0,y:0});
  const [originXY, setOriginXY] = React.useState<{x:number,y:number}>({x:0,y:0});
  const [pixelPoint1, setPixelPoint1] = React.useState<{x:number,y:number}>({x:0,y:0});
  const [pixelPoint2, setPixelPoint2] = React.useState<{x:number,y:number}>({x:0,y:0});
  const [valuePoint1, setValuePoint1] = React.useState<{x:number,y:number}>({x:0,y:0});
  const [valuePoint2, setValuePoint2] = React.useState<{x:number,y:number}>({x:0,y:0});
  const [readPoints, setReadPoints] = React.useState<{x:number,y:number}[]>([{x:100,y:100},{x:110,y:110}]);
  const [interPolatedData, setInterPolatedData] = React.useState<number[]>([]);
  const [interPolationType, setInterPolationType] = React.useState<string>("linear");
  const [latentNumber, setLatentNumber] = React.useState<number>(100);
  const [paletteOpen, setPaletteOpen] = React.useState<boolean>(false);
  const [color, setColor] = React.useState<string>("#FF0000");


  //以下の関数関係は別ファイルで管理できないか。

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleFiles = React.useCallback((e:React.ChangeEvent<HTMLInputElement>) => {
    // ファイル選択がキャンセルされた時は undefined TODO:この時の処理が必要
    e.target.files !== null ? console.log(URL.createObjectURL(e.target.files[0])) : console.log("")
    if(e.target.files!==null){
      console.log(e.target.files[0]);

      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0]) // FileをDataURL(base64)に変換

      reader.onload=()=>{ // 読込が完了したら発生するイベント
        // console.log(reader.result);
        if(typeof(reader.result)==='string'){
          setImgOrg(reader.result);
        }
      }
    }
  }, []);

  const clipboard=async()=>{
    const clipboardContents = await navigator.clipboard.read();
    for (const item of clipboardContents) {
      if (!item.types.includes('image/png')) {
        throw new Error('Clipboard contains non-image data.');
      }
      const blob = await item.getType('image/png');
      // destinationImage.src = URL.createObjectURL(blob);
      console.log(URL.createObjectURL(blob));
      console.log(blob);

      if(blob!==null){
        const reader = new FileReader()
        reader.readAsDataURL(blob) // FileをDataURL(base64)に変換

        reader.onload=()=>{ // 読込が完了したら発生するイベント
          // console.log(reader.result);
          if(typeof(reader.result)==='string'){
            setImgOrg(reader.result);
          }
        }
      }
    }
  }

  const calcReadValueX=(x:number)=>{
    if(logscaleX===false){
      const slopeX = (valuePoint2.x - valuePoint1.x)/(pixelPoint2.x - pixelPoint1.x)
      const valueX = slopeX * (x - pixelPoint1.x) + valuePoint1.x;
      return valueX.toFixed(3);
    }else{
      const slopeX = Math.pow(Math.pow(valuePoint2.x/valuePoint1.x,1/(pixelPoint2.x - pixelPoint1.x)),x-pixelPoint1.x);
      const valueX = valuePoint1.x * slopeX;
      return valueX.toFixed(3);
    }
  }

  const calcReadValueY=(y:number)=>{
    if(logscaleY===false){
      const slopeY = (valuePoint2.y - valuePoint1.y)/(pixelPoint2.y - pixelPoint1.y)
      const valueY = slopeY * (y - pixelPoint1.y) + valuePoint1.y;
      return valueY.toFixed(3);
    }else{
      const slopeY = Math.pow(Math.pow(valuePoint2.y/valuePoint1.y,1/(pixelPoint2.y - pixelPoint1.y)),y-pixelPoint1.y);
      const valueY = valuePoint1.y * slopeY;
      return valueY.toFixed(3);
    }
  }

  const handleStageClicked=(e:KonvaEventObject<MouseEvent>)=>{
    const newx = (e.evt.offsetX-originXY.x)/imgscale
    const newy = (e.evt.offsetY-originXY.y)/imgscale
    setClickedXY({x:newx,y:newy});

    const newvaluex = calcReadValueX(newx);
    const newvaluey = calcReadValueY(newy);

    if(state1&&state2&&tabValue===1){
      setReadValueStr(newvaluex+"\t"+newvaluey);

      const newrecorddata = recordData === "" ? newvaluex+"\t"+newvaluey : newvaluex+"\t"+newvaluey + "\n" +  recordData;
      setRecordData(newrecorddata);
    }
  }

  const handleClearRecord=()=>{
    setRecordData("");
  }

  const handleTestClick=()=>{
    console.log(pixelPoint2);
    console.log(valuePoint2);
    clipboard();
  }

  const handleResetClick=()=>{
    setOriginXY({x:0,y:0});
    setClickedXY({x:0,y:0});
    setImgScale(1);
    setRotate(0);
  }

  const handleAddClick=()=>{
    const newscale = imgscale >= 3 ? imgscale : imgscale+0.2;
        setImgScale(newscale);
  }

  const handleRemoveClick=()=>{
    const newscale = imgscale <= 0.3 ? imgscale : imgscale-0.2;
    setImgScale(newscale);
  }

  const handleMouseMove=(e: KonvaEventObject<MouseEvent>)=>{
    const newXY = {x:e.evt.offsetX,y:e.evt.offsetY};
    setMouseMoveXY(newXY);
  }

  const handleRotateClick=(direction:string)=>{
    if(direction==='clock-wise'){
      const newrotate =rotate-0.1;
      setRotate(newrotate);
    }else{
      const newrotate = rotate+0.1;
      setRotate(newrotate);
    }
  }

  const handleDragStart=(e: KonvaEventObject<DragEvent>,i:number)=>{
    const newxy = {
      x:e.target.x(),
      y:e.target.y(),
    };
    // if(readPoints!==null){
      const newreadpoints = [...readPoints.slice(0,i), newxy, ...readPoints.slice(i+1)];
      setReadPoints(newreadpoints);
    // }
  }

  const handleDragEnd=(e: KonvaEventObject<DragEvent>,i:number)=>{
    const newxy = {
      x:e.target.x(),
      y:e.target.y(),
    };
    if(readPoints!==null){
      const newreadpoints = [...readPoints.slice(0,i), newxy, ...readPoints.slice(i+1)];
      setReadPoints(newreadpoints);

      const newvalues = newreadpoints.map(nrp=>(
          String(calcReadValueX(nrp.x+5-originXY.x))+"\t"+String(calcReadValueY(nrp.y+5-originXY.y))+"\n"
        ))
      setReadPointValues(newvalues.join().replace(/,/g,""))
      setInterPolatedData([]);

      interPolationState && handleInterPolation(newreadpoints);
    }

  }

  const handleAddReadPoint=()=>{
    const newxy = {
      x:100+10*readPoints.length,
      y:100+10*readPoints.length,
    };
    const newreadpoints = [...readPoints, newxy];
    setReadPoints(newreadpoints);
  }

  const handleRemoveReadPoint=()=>{
    if(readPoints.length>2){
      const newreadpoints = [...readPoints.slice(0,-1)];
      setReadPoints(newreadpoints);
      const newvalues = newreadpoints.map(nrp=>(
        String(calcReadValueX(nrp.x+5-originXY.x))+"\t"+String(calcReadValueY(nrp.y+5-originXY.y))+"\n"
      ))
      setReadPointValues(newvalues.join().replace(/,/g,""))
    }

  }

  const handleInterPolation=(READPOINTS:{x:number,y:number}[])=>{
  //  axios.post('https://qpl98gtxdi.execute-api.ap-northeast-1.amazonaws.com/dev/api/interpolate',{"body":READPOINTS.sort((a,b)=>a.x-b.x),"type":interPolationType,"latent_number":latentNumber})
    axios.post('https://graphtrackerapi.jollymushroom-856a1da3.japaneast.azurecontainerapps.io',{"body":READPOINTS.sort((a,b)=>a.x-b.x),"type":interPolationType,"latent_number":latentNumber})
    .then((res)=>{
      console.log(res);
      const interpolated_x = JSON.parse(res.data.x_latent);
      const interpolated_y = JSON.parse(res.data.ip_result);

      const newreadpoints = interpolated_x.map((x:number,i:number)=>({x:x,y:interpolated_y[i]}));
      const newvalues = newreadpoints.map((nrp:any)=>(
        String(calcReadValueX(nrp.x+5-originXY.x))+"\t"+String(calcReadValueY(nrp.y+5-originXY.y))+"\n"
      ))
      setReadPointValues(newvalues.join().replace(/,/g,""))

      const interpolated_xy =  zip(interpolated_x,interpolated_y)?.join().split(',').map(v=>Number(v))
      interpolated_xy!==undefined && setInterPolatedData(interpolated_xy);
    })
  }

  const handleSelectPointColor=(color:string|undefined)=>{
    color!==undefined && setColor(color);
    setPaletteOpen(false);
  }

  return (
    <Grid container padding={1} spacing={1} display='flex' justifyContent='flex-start' alignItems='flex-start'>
      <Grid item xs={9}>
        <Grid item xs={12}>
          <ControlPanel
            handleFiles={handleFiles}
            handleTestClick={handleTestClick}
            handleResetClick={handleResetClick}
            handleAddClick={handleAddClick}
            handleRemoveClick={handleRemoveClick}
            handleRotateClick={handleRotateClick}
            handlePaletteClick={()=>setPaletteOpen(true)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{border:'1px solid black',borderRadius:'10px',p:1,height:"85vh"}}>
            <KonvaMain
              imgorg={imgorg}
              imgscale={imgscale}
              originXY={originXY}
              mouseMoveXY={mouseMoveXY}
              handleStageClicked={handleStageClicked}
              handleMouseMove={handleMouseMove}
              clickedXY={clickedXY}
              pixelPoint1={pixelPoint1}
              pixelPoint2={pixelPoint2}
              state1={state1}
              state2={state2}
              rotate={rotate}
              tabvalue={tabValue}
              readPoints={readPoints}
              handleDragStart={handleDragStart}
              handleDragEnd={handleDragEnd}
              interpolateddata={interPolatedData}
              color={color}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={3} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} sx={{height:"70px",alignItems:"flex-end"}}>
            <Tab label="基準点" />
            <Tab label="クリック" disabled={!state1||!state2}/>
            <Tab label="ポイント" disabled={!state1||!state2}/>
          </Tabs>
        </Box>
        <TabPanel1
          value={tabValue}
          index={0}
          clickedXY={clickedXY}
          state1={state1}
          setState1={setState1}
          state2={state2}
          setState2={setState2}
          pixelPoint1={pixelPoint1}
          setPixelPoint1={setPixelPoint1}
          pixelPoint2={pixelPoint2}
          setPixelPoint2={setPixelPoint2}
          valuePoint1={valuePoint1}
          setValuePoint1={setValuePoint1}
          valuePoint2={valuePoint2}
          setValuePoint2={setValuePoint2}
        />
        <TabPanel2
          value={tabValue}
          index={1}
          state1={state1}
          state2={state2}
          valuePoint1={valuePoint1}
          setValuePoint1={setValuePoint1}
          valuePoint2={valuePoint2}
          setValuePoint2={setValuePoint2}
          logscaleX={logscaleX}
          setLogscaleX={setLogscaleX}
          logscaleY={logscaleY}
          setLogscaleY={setLogscaleY}
          readValueStr={readValueStr}
          recordData={recordData}
          handleClearRecord={handleClearRecord}
        />
        <TabPanel3
          value={tabValue}
          index={2}
          handleAddReadPoint={handleAddReadPoint}
          handleRemoveReadPoint={handleRemoveReadPoint}
          readPointValues={readPointValues}
          logscaleX={logscaleX}
          setLogscaleX={setLogscaleX}
          logscaleY={logscaleY}
          setLogscaleY={setLogscaleY}
          interPolationType={interPolationType}
          setInterPolationType={setInterPolationType}
          latentNumber={latentNumber}
          setLatentNumber={setLatentNumber}
          interPolationState={interPolationState}
          setInterPolationState={setInterPolationState}
          handleInterPolation={()=>handleInterPolation(readPoints)}
        />
      </Grid>
      { paletteOpen && <ColorPicker open={true} onClose={(color)=>handleSelectPointColor(color)}/> }
    </Grid>

  )
}
