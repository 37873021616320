import { KonvaEventObject } from 'konva/lib/Node'
import React from 'react';
import { Stage, Layer, Image} from 'react-konva'
import useImage from 'use-image'
import { BasePointCircle } from './Konva/BasePointCircle';
import { CrossCursorLine } from './Konva/CrossCursorLine';
import { InterPolateLine } from './Konva/InterPolateLine';
import { ReadPointCircle } from './Konva/ReadPointCircle';

type Props={
  color:string
  imgorg:string,
  imgscale:number,
  rotate:number,
  tabvalue:number,
  state1:boolean,
  state2:boolean,
  originXY:{x:number,y:number},
  mouseMoveXY:{x:number,y:number},
  clickedXY:{x:number,y:number},
  pixelPoint1:{x:number,y:number},
  pixelPoint2:{x:number,y:number},
  readPoints:{x:number,y:number}[]|null,
  interpolateddata:number[],
  handleStageClicked:(e:KonvaEventObject<MouseEvent>)=>void,
  handleMouseMove:(e:KonvaEventObject<MouseEvent>)=>void,
  handleDragStart:(e: KonvaEventObject<DragEvent>,i:number)=>void,
  handleDragEnd:(e: KonvaEventObject<DragEvent>,i:number)=>void,
}


export default function KonvaMain(props:Props){
  const [image] = useImage(props.imgorg);


  return(
    <Stage
      width={window.innerWidth*0.75-30}
      height={window.innerHeight*0.85-20}
      scale={{x:props.imgscale,y:props.imgscale}}
      onClick={(e)=>props.handleStageClicked(e)}
      onMouseMove={(e)=>props.handleMouseMove(e)}
      draggable={false}
      x={props.originXY.x}
      y={props.originXY.y}
    >
      <Layer>
        <Image image={image} rotation={props.rotate} draggable={false}/>
        { (props.tabvalue===0 || props.tabvalue===1) &&
          <CrossCursorLine
            color={props.color}
            imgscale={props.imgscale}
            originXY={props.originXY}
            mouseMoveXY={props.mouseMoveXY}
            clickedXY={props.clickedXY}
          />
        }
        {props.tabvalue===0 &&
          <BasePointCircle
            color={props.color}
            imgscale={props.imgscale}
            state1={props.state1}
            state2={props.state2}
            pixelPoint1={props.pixelPoint1}
            pixelPoint2={props.pixelPoint2}
          />
        }
      </Layer>
      <Layer>
        {(props.tabvalue===2 && props.readPoints!==null) &&
          <ReadPointCircle
            color={props.color}
            readPoints={props.readPoints}
            handleDragStart={props.handleDragStart}
            handleDragEnd={props.handleDragEnd}
          />
        }
        {(props.tabvalue===2 && props.interpolateddata.length>0) &&
          <InterPolateLine
            color={props.color}
            interpolateddata={props.interpolateddata}
          />
        }
      </Layer>

    </Stage>
  )
}
