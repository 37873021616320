import { useState, useEffect, useLayoutEffect } from "react";

// 配列から最大値、最小値を取得する関数。この書き方の方が安全らしい・・・
export const getMaxFromArray=(arr:number[])=>{
  const getMax=(a: number,b: number)=>{return Math.max(a,b)};
  return arr.reduce(getMax);
}

export const getMinFromArray=(arr:number[])=>{
  const getMin=(a: number,b: number)=>{return Math.min(a,b)};
  return arr.reduce(getMin);
}

// blobURLからbase64に変換するカスタムフック。フック廻りはもっと勉強する必要あり
const convertDataUrl2Blob=async (dataUrl:any)=>{
  return await (await fetch(dataUrl)).blob()
}

export const useURL2Base64=(URL:any)=>{
  const [base64, setBase64] = useState<any>();

  useEffect(()=>{
    convertDataUrl2Blob(URL).then((blob)=>{
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload=(e)=>{
        setBase64(e.target?.result)
      }
  })
  },[URL])

  return {base64}
}

// windowの高さを取得するカスタムフック、他にも良い書き方があるかも
export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

// pythonのzip関数に相当、動作テスト未了
export const zip=(array1:number[], array2:number[])=>{
  if(array1.length!==array2.length){
    return;
  }
  const retarray = array1.map((v,i)=>([v,array2[i]]));
  return retarray;
}
