import { Box, Button, Checkbox, Divider, Grid, TextField, Tooltip, Typography,Autocomplete, IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props={
  index:number,
  value:number,
  children?: React.ReactNode,
  handleAddReadPoint:()=>void,
  handleRemoveReadPoint:()=>void,
  readPointValues:string,
  logscaleX:boolean;
  setLogscaleX:(state:boolean)=>void;
  logscaleY:boolean;
  setLogscaleY:(state:boolean)=>void;
  interPolationType:string|null;
  setInterPolationType:(type:string)=>void;
  latentNumber:number;
  setLatentNumber:(n:number)=>void;
  interPolationState:boolean;
  setInterPolationState:(state:boolean)=>void;
  handleInterPolation:()=>void;
}


export const TabPanel3=(props: Props)=>{

  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(props.readPointValues);
  };

  const handleInterPolationStateChange=(checked: boolean)=>{
    props.setInterPolationState(checked);
    if(checked){
      props.handleInterPolation();
    }
  }

  return(
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <div style={{display:'flex'}}>
                <Typography sx={{p:1}}>
                  対数目盛
                </Typography>
                <Tooltip title='log scale x'>
                  <Checkbox checked={props.logscaleX} onChange={(e)=>props.setLogscaleX(e.target.checked)}/>
                </Tooltip>
                <Tooltip title='log scale y'>
                  <Checkbox checked={props.logscaleY} onChange={(e)=>props.setLogscaleY(e.target.checked)}/>
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6} sx={{paddingRight:1}}>
              <Button fullWidth onClick={()=>props.handleAddReadPoint()}>ポイント追加</Button>
            </Grid>
            <Grid item xs={6} sx={{paddingLeft:1}}>
              <Button fullWidth onClick={()=>props.handleRemoveReadPoint()}>ポイント削除</Button>
            </Grid>
            <Grid item xs={12} sx={{p:1}}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title='interpolate'>
                <Checkbox checked={props.interPolationState} onChange={(e)=>handleInterPolationStateChange(e.target.checked)}/>
              </Tooltip>
              <Tooltip title='copy'>
                <IconButton onClick={copyToClipboard}>
                  <ContentCopyIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
            {props.interPolationState &&
              <Grid item xs={12}>
                <Box sx={{display:"flex"}}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    options={["linear","spline"]}
                    defaultValue={props.interPolationType}
                    value={props.interPolationType}
                    onChange={(e,v)=>{v!==null && props.setInterPolationType(v)}}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => <TextField  {...params} label="補間方法" />}
                  />
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={["100","200","300","500","1000","10000","100000"]}
                    value={String(props.latentNumber)}
                    onChange={(e,v)=>v!==null && props.setLatentNumber(Number(v))}
                    defaultValue={String(props.latentNumber)}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => <TextField {...params} label="データ点数" />}
                  />
                </Box>
              </Grid>
            }
            <Grid item xs={12}>
              <TextField
                label="values[x y]"
                multiline
                maxRows={10}
                fullWidth
                value={props.readPointValues}
                size="small"
                variant="filled"
              />
            </Grid>
        </Grid>
      </Box>
      )}
    </div>
  )
}
