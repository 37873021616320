import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { ColorResult,CirclePicker } from 'react-color';

type Props = {
  open: boolean,
  onClose: (textColor?:string) => void,
}

export default function ColorPicker(props:Props){
  const [textColor, setTextColor] = React.useState<string>("#FF0000");

  const changeTextColor = (color:ColorResult) => {
    setTextColor(color.hex);
  }

  return(
    <Dialog open={props.open}>
      <DialogContent>
        <CirclePicker
          color={textColor}
          onChange={changeTextColor}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>props.onClose(textColor)}>OK</Button>
        <Button onClick={()=>props.onClose()}>Cancel</Button>
      </DialogActions>
    </Dialog>

  )
}
