import { Box, Checkbox, Divider, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { NumericFormat } from 'react-number-format';

type Props={
  index:number,
  value:number,
  children?: React.ReactNode,
  clickedXY:{x:number,y:number},
  state1:boolean,
  setState1:(state:boolean)=>void,
  state2:boolean,
  setState2:(state:boolean)=>void,
  pixelPoint1:{x:number,y:number},
  setPixelPoint1:(point:{x:number,y:number})=>void,
  pixelPoint2:{x:number,y:number},
  setPixelPoint2:(point:{x:number,y:number})=>void,
  valuePoint1:{x:number,y:number},
  setValuePoint1:(point:{x:number,y:number})=>void,
  valuePoint2:{x:number,y:number},
  setValuePoint2:(point:{x:number,y:number})=>void,
}

export const TabPanel1=(props:Props)=>{

  const handleValueChange=(id:string,v:number)=>{
    const newvalue = v;
    if(id === 'value_x1'){
      const newpoint = {x:newvalue,y:props.valuePoint1.y};
      props.setValuePoint1(newpoint);
    }else if(id === 'value_y1'){
      const newpoint = {x:props.valuePoint1.x,y:newvalue};
      props.setValuePoint1(newpoint);
    }else if(id === 'value_x2'){
      const newpoint = {x:newvalue,y:props.valuePoint2.y};
      props.setValuePoint2(newpoint);
    }else if(id === 'value_y2'){
      const newpoint = {x:props.valuePoint2.x,y:newvalue};
      props.setValuePoint2(newpoint);
    }
  }

  return(
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
    >
      {props.value === props.index && (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <div style={{display:'flex',alignItems:'center'}}>
                <Typography sx={{p:1}}>
                  基準点-1
                </Typography>
                <Tooltip title='基準点-1'>
                  <Checkbox checked={props.state1} onChange={(e)=>{props.setState1(e.target.checked);props.setPixelPoint1(props.clickedXY)}}/>
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{display:'flex',alignItems:'center'}}>
                <TextField
                  label='x-pixel'
                  error={!props.state1}
                  value={!props.state1 ? props.clickedXY.x : props.pixelPoint1.x}
                  disabled
                  size="small"
                  type='number'
                  sx={{p:1,width:100}}/>
                <DoubleArrowIcon color='primary'/>
                <NumericFormat
                  sx={{p:1,width:100}}
                  label="x-value"
                  size="small"
                  error={!props.state1}
                  customInput={TextField}
                  onValueChange={(values)=>{
                    handleValueChange('value_x1',Number(values.value))
                  }}
                  value={props.valuePoint1.x}
                  disabled={props.state1}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{display:'flex',alignItems:'center'}}>
                <TextField
                  label='y-pixel'
                  error={!props.state1}
                  value={!props.state1 ? props.clickedXY.y : props.pixelPoint1.y}
                  disabled
                  size="small"
                  type='number'
                  sx={{p:1,width:100}}
                />
                <DoubleArrowIcon color='primary'/>
                <NumericFormat
                  sx={{p:1,width:100}}
                  label="y-value"
                  size="small"
                  error={!props.state1}
                  customInput={TextField}
                  onValueChange={(values)=>{
                    handleValueChange('value_y1',Number(values.value))
                  }}
                  value={props.valuePoint1.y}
                  disabled={props.state1}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>

            <Grid item xs={12}>
              <div style={{display:'flex',alignItems:'center'}}>
                <Typography sx={{p:1}}>
                  基準点-2
                </Typography>
                <Tooltip title='基準点-2'>
                  <Checkbox checked={props.state2} onChange={(e)=>{props.setState2(e.target.checked);props.setPixelPoint2(props.clickedXY)}}/>
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{display:'flex',alignItems:'center'}}>
                <TextField
                  label='x-pixel'
                  error={!props.state2}
                  value={!props.state2 ? props.clickedXY.x : props.pixelPoint2.x}
                  disabled
                  size="small"
                  type='number'
                  sx={{p:1,width:100}}/>
                <DoubleArrowIcon color='primary'/>
                <NumericFormat
                  sx={{p:1,width:100}}
                  label="x-value"
                  size="small"
                  error={!props.state2}
                  customInput={TextField}
                  onValueChange={(values)=>{
                    handleValueChange('value_x2',Number(values.value))
                  }}
                  value={props.valuePoint2.x}
                  disabled={props.state2}
                  InputLabelProps={{ shrink: true }}
                />
                </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{display:'flex',alignItems:'center'}}>
                <TextField
                  label='y-pixel'
                  error={!props.state2}
                  value={!props.state2 ? props.clickedXY.y : props.pixelPoint2.y}
                  disabled
                  size="small"
                  type='number'
                  sx={{p:1,width:100}}
                />
                <DoubleArrowIcon color='primary'/>
                <NumericFormat
                  sx={{p:1,width:100}}
                  label="y-value"
                  size="small"
                  error={!props.state2}
                  customInput={TextField}
                  onValueChange={(values)=>{
                    handleValueChange('value_y2',Number(values.value))
                  }}
                  value={props.valuePoint2.y}
                  disabled={props.state2}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  )
}
