import React from 'react';
import { Line } from 'react-konva'

type Props={
  color:string,
  interpolateddata:number[]
}

export const InterPolateLine=(props:Props)=>{

  return(
    <Line points={props.interpolateddata}
    stroke={props.color} strokeWidth={1}
    />
  )
}
