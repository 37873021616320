import React from 'react';
import { Line } from 'react-konva'


type Props={
  color:string,
  imgscale:number,
  originXY:{x:number,y:number},
  mouseMoveXY:{x:number,y:number},
  clickedXY:{x:number,y:number},
}

export const CrossCursorLine=(props:Props)=>{
  return(
    <>
      <Line points={[
        (props.mouseMoveXY.x -5-props.originXY.x)/props.imgscale,
        (props.mouseMoveXY.y-15-props.originXY.y)/props.imgscale,
        (props.mouseMoveXY.x -5-props.originXY.x)/props.imgscale,
        (props.mouseMoveXY.y +5-props.originXY.y)/props.imgscale
        ]}
        stroke={props.color} strokeWidth={1}
      />
      <Line points={[
        (props.mouseMoveXY.x-15-props.originXY.x)/props.imgscale,
        (props.mouseMoveXY.y -5-props.originXY.y)/props.imgscale,
        (props.mouseMoveXY.x +5-props.originXY.x)/props.imgscale,
        (props.mouseMoveXY.y -5-props.originXY.y)/props.imgscale
        ]}
        stroke={props.color} strokeWidth={1}
        />
      <Line points={[
        props.clickedXY.x-5/props.imgscale,
        0,
        props.clickedXY.x-5/props.imgscale,
        window.innerWidth*3,

        ]}
        stroke={props.color} strokeWidth={1}
        />
      <Line points={[
        0,
        props.clickedXY.y-5/props.imgscale,
        window.innerHeight*3,
        props.clickedXY.y-5/props.imgscale
        ]}
        stroke={props.color} strokeWidth={1}
        />
    </>
  )
}
